import React from "react"

// Libraries
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"
import Lottie from "components/lottie-observed/"
import Quote from "components/case-study-quote/"

// Animations
import Animation from "assets/animations/case-studies/hpims/the-challenge-animation.json"

const StyledSection = styled(Section)`
  .section__animation {
    margin: 32px 0;

    ${breakpoint.medium`
      margin: 80px 0;
    `}
  }
`

const Challange = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/hpims/gini.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The challenge</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              How can we join together a cutting-edge research institute and an
              internationally acclaimed healthcare system so that they can
              revolutionize digital health?
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              When the idea for HPI·MS was born, the Hasso Plattner Institute in
              Potsdam, Germany brought expertise in digital engineering. Mount
              Sinai Health System, based in New York City, brought access to a
              wealth of health data and healthcare professionals. Both were
              global leaders in knowledge and research in their fields. So, how
              could they come together across the sea to make something even
              bigger?
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              That’s when we stepped in. First, these two superpowers needed to
              define their new brand in a way that showcased what both brought
              to the table in the digital health field. Then, they needed a
              platform that would make their projects and publications both
              visible to the public and accessible to the scientific community.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              Together, we needed to make something that made everyone proud, so
              that HPI·MS could show the world how digital health is done.
            </Trans>
          </p>
        </div>
      </div>

      <div className="section__animation">
        <Lottie animation={Animation} />
      </div>

      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <Quote
            quote={t(
              "“By working with the client and making them part of our team, we were able to gain the insight we needed and surpass our own expectations.”"
            )}
            author="Gini"
            position={t("Operations Manager")}
            image={data.file.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />
        </div>
      </div>
    </StyledSection>
  )
}

export default Challange