import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

// Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"
import Carousel from "components/case-study-carousel/"

const StyledSection = styled(Section)`
  padding-top: 56px !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const StyledCarousel = styled(Carousel)`
  width: 100%;
  margin-top: 40px;

  ${breakpoint.medium`
    margin-top: 80px;
  `}

  .slide {
    max-width: 928px;
  }
`

const Results = () => {
  const data = useStaticQuery(graphql`
    query {
      carouselImageOne: file(
        relativePath: { eq: "case-studies/hpims/the-results-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageTwo: file(
        relativePath: { eq: "case-studies/hpims/the-results-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageThree: file(
        relativePath: { eq: "case-studies/hpims/the-results-image-3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFour: file(
        relativePath: { eq: "case-studies/hpims/the-results-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      carouselImageFive: file(
        relativePath: { eq: "case-studies/hpims/the-results-image-5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <StyledSection>
      <div className="container">
        <div className="section__title">
          <div className="sticky">
            <p>
              <Trans>The results</Trans>
            </p>
          </div>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              A consolidated brand partnership and a website that showcases the
              institute’s work, while allowing updates as the team continues
              growing and advancing.
            </Trans>
          </h3>
          <br />
          <p>
            <Trans>
              From a logo that expertly combines and represents both
              organizations, to a platform that effectively manages data for
              worldwide access by healthcare professionals, students, and
              researchers, the HPI·MS website showcases the full power of this
              digital health icon. With its sleek, user-friendly design, HPI·MS
              can now feature their most important research endeavours and
              publications.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              But it doesn’t stop there. Indicius introduced the team to an
              easy-to-use Content Management System, so that HPI·MS members
              across the globe can constantly update the platform to share their
              advancements.
            </Trans>
          </p>
          <br />
          <p>
            <Trans>
              HPI·MS just keeps on growing. Now their site can, too.
            </Trans>
          </p>
        </div>
      </div>
      <StyledCarousel id="resultsCarousel">
        <div data-slide className="slide align--with-title">
          <Img
            fluid={data.carouselImageOne.childImageSharp.fluid}
            alt="HPI·MS"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFive.childImageSharp.fluid}
            alt="HPI·MS"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageThree.childImageSharp.fluid}
            alt="HPI·MS"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageFour.childImageSharp.fluid}
            alt="HPI·MS"
          />
        </div>
        <div data-slide className="slide">
          <Img
            fluid={data.carouselImageTwo.childImageSharp.fluid}
            alt="HPI·MS"
          />
        </div>
      </StyledCarousel>
    </StyledSection>
  )
}

export default Results