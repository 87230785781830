import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Section from "components/case-study-section/"

const StyledSection = styled(Section)`
  padding-bottom: 0 !important;
  background-color: ${colors.cream};

  .image {
    width: 100%;
    margin-top: 32px;

    ${breakpoint.medium`
      margin-top: 80px;
    `}
  }
`

const FullWebsite = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "case-studies/hpims/new-website-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 928, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledSection>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p>
            <Trans>
              This efficiency was made possible in part by the strategic use of
              wireframes: outlines of each page of the website to organize all
              the content before the final images went in.
            </Trans>
          </p>
        </div>

        <Img
          className="image"
          fluid={data.file.childImageSharp.fluid}
          alt="New Marketing Website"
        />
      </div>
    </StyledSection>
  )
}

export default FullWebsite
