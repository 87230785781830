import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"
import Quote from "components/case-study-quote/"

const StyledQuote = styled(Quote)`
  margin-top: 80px;
`

const StyledSection = styled(Section)`
  padding-top: 56px !important;
  background-color: ${colors.cream};
  overflow-x: hidden !important;

  ${breakpoint.medium`
    padding-top: 120px!important;
  `}
`

const ImageWrapper = styled.div`
  max-width: 1280px;
  margin: 32px auto 0 auto;

  ${breakpoint.medium`
    margin-top: 80px;
  `}

  .image {
    max-width: calc(100% - 16px);
    display: block;
    margin-bottom: 32px;

    ${breakpoint.small`
      max-width: 75%;
    `}

    ${breakpoint.medium`
      margin-bottom: 80px;
    `}

    &:nth-child(even) {
      margin-left: auto;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Solution = () => {
  const data = useStaticQuery(graphql`
    query {
      saskia: file(relativePath: { eq: "case-studies/hpims/saskia.png" }) {
        childImageSharp {
          fixed(width: 48, height: 48, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      imageOne: file(
        relativePath: { eq: "case-studies/hpims/the-solution-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      imageTwo: file(
        relativePath: { eq: "case-studies/hpims/the-solution-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const { t } = useTranslation()

  return (
    <StyledSection backgroundColor={colors.cream}>
      <div className="container">
        <div className="section__title">
          <p>
            <Trans>The solution</Trans>
          </p>
        </div>

        <div className="section__content">
          <h3>
            <Trans>
              Creating a brand that represents both institutes and a platform
              for collaborating with an international community of researchers
              and students
            </Trans>
          </h3>
          <br />
          <p className="title">
            <Trans>Brand alignment</Trans>
          </p>
          <p>
            <Trans>
              The first step of the process was aligning these two key players
              in their field, defining the “why” of their organization and how
              they wanted to present their partnership to the world. Excitingly,
              the brand was then unveiled at the HPI Mount Sinai Digital Health
              forum, where it got great feedback--a huge step in the right
              direction for the partnership.
            </Trans>
          </p>
        </div>
      </div>

      <ImageWrapper>
        <div
          className="image"
          data-aos="fadeIn"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Img fluid={data.imageOne.childImageSharp.fluid} alt="HPI·MS" />
        </div>
        <div
          className="image"
          data-aos="fadeIn"
          data-aos-duration="600"
          data-aos-offset="180"
        >
          <Img fluid={data.imageTwo.childImageSharp.fluid} alt="HPI·MS" />
        </div>
      </ImageWrapper>

      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <StyledQuote
            quote={t(
              "“HPI has its own branding, Mount Sinai has its own branding, and to bring those two together and not lose the identities of one of them, I think that is a very difficult task to solve. I think you guys did a wonderful job with that.”"
            )}
            author="Saskia"
            position={t(
              "The Hasso Plattner Institute for Digital Health at Mount Sinai"
            )}
            image={data.saskia.childImageSharp.fixed}
            backgroundColor={colors.indiblue}
            color={colors.white}
          />
        </div>
      </div>
    </StyledSection>
  )
}

export default Solution