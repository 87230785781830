import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Section from "components/case-study-section/"

const ImageWrapper = styled.div`
  max-width: 1440px;
  margin: 16px auto 0 auto;

  ${breakpoint.medium`
    margin-top: 60px;
  `}

  .image {
    width: 95%;
    max-width: 1280px;
    display: block;

    &:nth-child(odd) {
      margin-left: auto;
    }

    ${breakpoint.medium`
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    `}
  }
`

const WebsiteCreation = () => {
  const data = useStaticQuery(graphql`
    query {
      imageOne: file(
        relativePath: { eq: "case-studies/hpims/website-creation-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageTwo: file(
        relativePath: { eq: "case-studies/hpims/website-creation-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Section>
      <div className="container">
        <div className="section__title"></div>
        <div className="section__content">
          <p className="title">
            <Trans>Website creation</Trans>
          </p>
          <p>
            <Trans>
              Next came building a roadmap towards the creation of a website
              that showcased their new branding, while managing extensive
              content so that it would be accessible to the scientific
              community. A design sprint and iteration sprint allowed us to
              create a prototype validated by both institutions, to ensure
              everyone felt it represented them. In this phase Indi employed
              innovative design and development processes in tandem with each
              other, which provided the team flexibility and allowed the website
              to be created in half the time allotted.
            </Trans>
          </p>
        </div>
      </div>
      <ImageWrapper>
        <Img
          className="image"
          fluid={data.imageOne.childImageSharp.fluid}
          alt="Illustration System"
        />
        <Img
          className="image"
          fluid={data.imageTwo.childImageSharp.fluid}
          alt="Illustration System"
        />
      </ImageWrapper>
    </Section>
  )
}

export default WebsiteCreation
